<template>
  <v-card class="table-page">
    <div v-if="donations.length">
      <h2 class="px-5 pt-5 mb-6">Doações</h2>
      <v-divider />
      <v-expansion-panels accordion flat>
        <expansion-panel v-for="(donation, i) in donations" :key="i">
          <template slot="title">
            {{ donation.createdAt | formatDonationName }}
          </template>
          <template slot="content">
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="donation.donateStocks"
              :items-per-page="donation.donateStocks.length || 0"
            ></v-data-table>
          </template>
        </expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else class="inherit-height d-flex flex-column justify-center">
      <data-not-found
        title="Nenhuma doação foi encontrada!"
        :imgSrc="require('@/assets/equipment.svg')"
      />
    </div>
  </v-card>
</template>

<script>
  import { mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";
  import ExpansionPanel from "@/modules/core/views/components/ExpansionPanel";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { format } from "date-fns";

  export default {
    name: "ListDonation",
    components: {
      DataNotFound,
      ExpansionPanel
    },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    mixins: [MasksMixin],
    data: () => ({
      donations: [],
      headers: [
        {
          text: "CRC de origem",
          value: "stock.crc.description",
          sortable: false
        },
        {
          text: "Equipamento",
          value: "stock.equipment.model",
          sortable: false
        },
        {
          text: "Marca",
          value: "stock.equipment.brand.description",
          sortable: false
        },
        {
          text: "Modelo",
          value: "stock.equipment.equipmentType.description",
          sortable: false
        },
        {
          text: "Quantidade",
          value: "amount",
          sortable: false
        }
      ]
    }),
    async mounted() {
      await this.fetchDonations();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getDonations"]),
      async fetchDonations() {
        try {
          const { data } = await this.getDonations(this.id);
          this.donations = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    },
    filters: {
      formatDonationName(createdAt) {
        const formatedDate = format(new Date(createdAt), "dd/MM/yyyy - HH:mm");
        return `Doação realizada em ${formatedDate}`;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
