<template>
  <v-container>
    <title-header :title="grantee.name" />
    <v-row>
      <v-col cols="12" lg="4">
        <info-person-pf v-if="grantee" title="Donatário" :person="grantee" />
      </v-col>
      <v-col cols="12" lg="8">
        <list-donation v-if="granteeId" :id="granteeId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";

  import ListDonation from "@/modules/stock/destination/views/components/ListDonation";
  import InfoPersonPF from "@/modules/core/views/components/InfoPersonPF";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  export default {
    name: "DetailGranteePF",
    components: { "info-person-pf": InfoPersonPF, ListDonation, TitleHeader },
    data: () => ({
      granteeId: null,
      grantee: {}
    }),
    async mounted() {
      this.initializeGranteeId();
      await this.fetchDonator();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getGranteePF"]),
      initializeGranteeId() {
        this.granteeId = parseInt(this.$route.params.id, 10);
      },
      async fetchDonator() {
        try {
          const { data } = await this.getGranteePF(this.granteeId);
          this.grantee = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
