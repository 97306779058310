<template>
  <v-card>
    <div class="px-10 pt-5 mb-6">
      <h2>{{ title }}</h2>
      <v-row v-if="person.name">
        <v-col cols="5" md="4">
          <span class="black-3--text">Nome:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ person.name }}</span>
        </v-col>
      </v-row>
      <v-row v-if="person.cpf">
        <v-col cols="5" md="4">
          <span class="black-3--text">CPF:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ person.cpf | VMask(masks.cpf) }}</span>
        </v-col>
      </v-row>
      <v-row v-if="person.email">
        <v-col cols="5" md="4">
          <span class="black-3--text">E-mail:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ person.email }}</span>
        </v-col>
      </v-row>
      <v-row v-if="phones">
        <v-col cols="5" md="4">
          <span class="black-3--text">Telefone(s):</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ phones }}</span>
        </v-col>
      </v-row>
      <v-row v-if="address">
        <v-col cols="5" md="4"
          ><span class="black-3--text">Endereço:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ address }}</span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";
  import { address, title } from "faker-br/lib/locales/az";

  export default {
    name: "InforPersonPF",
    mixins: [MasksMixin],
    props: {
      title: {
        type: String,
        required: true
      },
      person: {
        type: Object,
        required: true
      }
    },
    computed: {
      address() {
        const { address } = this.person;
        if (!address) return "";
        return parseAdressSingleLine(address);
      },
      phones() {
        const { phones } = this.person;
        if (!phones) return "";
        return phones.map(phone => this.parsePhone(phone)).join(", ");
      }
    }
  };
</script>

<style lang="scss" scoped></style>
